/* ScrollableDiv.css */

.custom-scrollbar {
    overflow-x: auto;
    overflow-y: hidden;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 0px;
    /* Largura da barra de rolagem */
    height: 0px;
    /* Altura da barra de rolagem (no eixo Y) */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: darkgray;
    /* Cor do polegar da barra de rolagem */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: lightgray;
    /* Cor da trilha da barra de rolagem */
}