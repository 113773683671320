.data-com-on {
    position: relative;
    display: inline-block;
}

.border-pulse {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    animation: pulse 1s infinite alternate;
}

.border-green {
    border: 3px solid #28ff1abf;
}
.border-red {
    border: 3px solid #fa121ebf;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}