.custom-text-right {
    text-align: right;
}

.responsive-text {
    font-size: 1rem; /* Tamanho de fonte inicial */
  }
  
  @media (max-width: 768px) {
    .responsive-text {
      font-size: 0.5rem; /* Tamanho de fonte para telas menores que 768px */
    }
  }
  
  @media (max-width: 576px) {
    .responsive-text {
      font-size: 0.25rem; /* Tamanho de fonte para telas menores que 576px */
    }
  }