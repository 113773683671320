::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    /* background: #353535a8; */
    background: #b8b8b8a8;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(54, 54, 54);

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(112, 112, 112);
}