.loadingBtn:after {
    content: "";
    display: inline-block;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    margin-right: 20px;
    border-radius: 50%;
    border: 3px solid white;
    border-left-color: transparent;
    border-top-color: transparent;
    animation: loading 1s linear infinite;
  }
  
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }