.table-scroll {
  max-height: 55vh;
  padding-right: 5px;
}

.head-sticky {
  position: sticky;
  top: 0;
  z-index: 3
}

/* Estilo geral para tornar a tabela responsiva */
.table-sticky {
  overflow-x: auto;
}

/* Estilos para telas com largura máxima de 767px */
@media (max-width: 767px) {
  .table-scroll {
    max-height: 60vh;
  }

  /* Revertendo o overflow-x e adicionando position: relative para a classe .table-sticky */
  .table-sticky {
    overflow-x: initial;
    position: relative;
  }

  /* Tornando a primeira célula do cabeçalho fixa somente na tabela com classe .table-sticky */
  .table-sticky th:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
  }

  /* Tornando a primeira célula de cada linha no tbody fixa na tabela com classe .table-sticky */
  .table-sticky tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
  }


  .table-sticky th:nth-child(3) {
    position: sticky;
    left: 3.5rem;
    z-index: 1;
    background-color: white;
  }

  .table-sticky tbody td:nth-child(3) {
    position: sticky;
    left: 3.5rem;
    z-index: 1;
    background-color: white;
  }

  /* Aplicando position: relative para todas as células do cabeçalho e do tbody na tabela com classe .table-sticky */
  .table-sticky th,
  .table-sticky tbody td {
    position: relative;
    /* min-width: 100px; */
    /* Defina um tamanho mínimo para as colunas, se necessário */
  }
}